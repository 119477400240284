import React from "react"

const Partners = () => (
  <div className="promotion-section">
    <div className="container">
      <ul className="promo-partners">
        <li>
          <i className="icon icon_google-partner" />
        </li>
        <li>
          <i className="icon icon_bing" />
        </li>
        <li>
          <i className="icon icon_callrail" />
        </li>
        <li>
          <i className="icon icon_sempo" />
        </li>
        <li className="invisible-mobile">
          <i className="icon icon_unbounce" />
        </li>
      </ul>
    </div>
  </div>
)

export default Partners
